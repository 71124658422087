import * as i0 from '@angular/core';
import { Injectable } from '@angular/core';
function createInjectable(factory, {
  providedIn = 'root'
} = {}) {
  class _Injectable {
    constructor() {
      const result = factory();
      for (const key of Reflect.ownKeys(result)) {
        Object.defineProperty(this, key, {
          get: () => result[key],
          set: value => {
            result[key] = value;
          },
          enumerable: true,
          configurable: true
        });
      }
    }
    static {
      this.ɵfac = function _Injectable_Factory(__ngFactoryType__) {
        return new (__ngFactoryType__ || _Injectable)();
      };
    }
    static {
      this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
        token: _Injectable,
        factory: _Injectable.ɵfac,
        providedIn: providedIn === 'scoped' ? null : providedIn
      });
    }
  }
  (() => {
    (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(_Injectable, [{
      type: Injectable,
      args: [{
        providedIn: providedIn === 'scoped' ? null : providedIn
      }]
    }], () => [], null);
  })();
  if (factory.name) {
    Object.defineProperty(_Injectable, 'name', {
      value: `_Injectable_${factory.name}`
    });
  }
  return _Injectable;
}

/**
 * Generated bundle index. Do not edit.
 */

export { createInjectable };
