{
  "name": "laguna.bay.webapp",
  "version": "1.3.0",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "watch": "ng build --watch --configuration development",
    "test": "ng test"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^19.1.7",
    "@angular/cdk": "^19.1.5",
    "@angular/common": "^19.1.7",
    "@angular/compiler": "^19.1.7",
    "@angular/core": "^19.1.7",
    "@angular/forms": "^19.1.7",
    "@angular/material": "^19.1.5",
    "@angular/platform-browser": "^19.1.7",
    "@angular/platform-browser-dynamic": "^19.1.7",
    "@angular/router": "^19.1.7",
    "@auth0/angular-jwt": "^5.2.0",
    "@fortawesome/angular-fontawesome": "^1.0.0",
    "@fortawesome/duotone-regular-svg-icons": "^6.7.2",
    "@fortawesome/fontawesome-free": "^6.5.1",
    "@microsoft/signalr": "^8.0.7",
    "@primeng/themes": "^19.0.8",
    "@taiga-ui/cdk": "^4.5.0",
    "@taiga-ui/core": "^4.5.0",
    "@taiga-ui/icons": "^4.5.0",
    "@taiga-ui/kit": "^4.5.0",
    "@taiga-ui/layout": "^4.8.1",
    "@taiga-ui/legacy": "^4.5.0",
    "@taiga-ui/styles": "^4.5.0",
    "@tinymce/tinymce-angular": "^8.0.1",
    "@turf/turf": "^6.5.0",
    "@types/arcgis-rest-api": "^10.4.8",
    "@types/jsts": "^0.17.24",
    "file-saver": "^2.0.5",
    "fontawesome-free": "^1.0.4",
    "geostyler-legend": "^5.0.0",
    "geostyler-openlayers-parser": "^4.3.0",
    "geostyler-style": "^8.1.0",
    "jsts": "^2.11.0",
    "lodash": "^4.17.21",
    "ngx-color-picker": "^16.0.0",
    "ngx-colors": "^3.5.2",
    "ngx-online-status": "^2.0.0",
    "ngx-permissions": "^17.1.0",
    "ngx-skeleton-loader": "^9.0.0",
    "ngxtension": "^4.3.2",
    "ol": "^8.1.0",
    "ol-esri-style": "^3.0.0",
    "open-props": "^1.5.10",
    "primeng": "^19.0.8",
    "proj4": "^2.8.0",
    "quill": "^1.3.7",
    "rxjs": "~7.8.0",
    "taiga-ui": "^4.25.0",
    "tailwind-merge": "^2.5.4",
    "tinymce": "^7.3.0",
    "ts-results": "^3.3.0",
    "tslib": "^2.7.0",
    "uuid": "^9.0.1",
    "xstate": "^5.19.2",
    "zone.js": "~0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^19.1.8",
    "@angular/cli": "~19.1.8",
    "@angular/compiler-cli": "^19.1.7",
    "@types/jasmine": "~4.3.0",
    "autoprefixer": "^10.4.20",
    "jasmine-core": "~4.6.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "postcss": "^8.4.47",
    "release-it": "^17.10.0",
    "tailwindcss": "^3.4.14",
    "typescript": "~5.7.3"
  }
}
