import { Component, inject } from '@angular/core';
import { MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
    selector: 'app-notification-toast',
    templateUrl: './notification-toast.component.html',
    styleUrls: ['./notification-toast.component.css'],
    standalone: false
})
export class NotificationToastComponent {
  snackBarRef = inject(MatSnackBarRef);
}
