import { DOCUMENT } from '@angular/common';
import { InjectionToken, inject, DestroyRef, signal } from '@angular/core';
import { assertInjector } from 'ngxtension/assert-injector';
const NGXTENSION_LOCAL_STORAGE = new InjectionToken('NGXTENSION_LOCAL_STORAGE', {
  providedIn: 'root',
  factory: () => localStorage // this would be the default
});
function provideLocalStorageImpl(impl) {
  return {
    provide: NGXTENSION_LOCAL_STORAGE,
    useValue: impl
  };
}
function isLocalStorageWithDefaultValue(options) {
  return 'defaultValue' in options;
}
function isFunction(value) {
  return typeof value === 'function';
}
function goodTry(tryFn, defaultValue) {
  try {
    return tryFn();
  } catch {
    return defaultValue;
  }
}
function parseJSON(value) {
  return value === 'undefined' ? undefined : JSON.parse(value);
}
const injectLocalStorage = (key, options = {}) => {
  if (isLocalStorageWithDefaultValue(options)) {
    const defaultValue = isFunction(options.defaultValue) ? options.defaultValue() : options.defaultValue;
    return internalInjectLocalStorage(key, options, defaultValue);
  }
  return internalInjectLocalStorage(key, options, undefined);
};
const internalInjectLocalStorage = (key, options, defaultValue) => {
  const stringify = isFunction(options.stringify) ? options.stringify : JSON.stringify;
  const parse = isFunction(options.parse) ? options.parse : parseJSON;
  const storageSync = options.storageSync ?? true;
  return assertInjector(injectLocalStorage, options.injector, () => {
    const localStorage = inject(NGXTENSION_LOCAL_STORAGE);
    const destroyRef = inject(DestroyRef);
    const window = inject(DOCUMENT).defaultView;
    if (!window) {
      throw new Error('Cannot access to window element');
    }
    const initialStoredValue = goodTry(() => localStorage.getItem(key), null);
    const internalSignal = signal(initialStoredValue ? goodTry(() => parse(initialStoredValue), defaultValue) : defaultValue);
    const syncValueWithLocalStorage = value => {
      const newValue = goodTry(() => value === undefined ? null : stringify(value), null);
      try {
        if (newValue === localStorage.getItem(key)) {
          return;
        }
        if (newValue === null) {
          localStorage.removeItem(key);
        } else {
          localStorage.setItem(key, newValue);
        }
        // We notify other consumers in this tab about changing the value in the store for synchronization
        window.dispatchEvent(new StorageEvent(`storage`, {
          key,
          newValue,
          storageArea: localStorage
        }));
      } catch {
        // ignore errors
      }
    };
    if (storageSync) {
      const originalSet = internalSignal.set;
      const originalUpdate = internalSignal.update;
      const set = newValue => {
        // set the value in the signal using the original set function
        originalSet(newValue);
        // then we refresh the value in localStorage and notify other consumers in this tab about the change
        syncValueWithLocalStorage(newValue);
      };
      const update = updateFn => {
        let newValue;
        // set the value in the signal using the original set function
        originalUpdate(value => newValue = updateFn(value));
        // then we refresh the value in localStorage and notify other consumers in this tab about the change
        syncValueWithLocalStorage(newValue);
      };
      internalSignal.set = set;
      internalSignal.update = update;
      const onStorage = event => {
        if (event.storageArea === localStorage && event.key === key) {
          const newValue = event.newValue !== null ? parse(event.newValue) : defaultValue;
          internalSignal.set(newValue);
        }
      };
      window.addEventListener('storage', onStorage);
      destroyRef.onDestroy(() => {
        window.removeEventListener('storage', onStorage);
      });
    }
    return internalSignal;
  });
};

/**
 * Generated bundle index. Do not edit.
 */

export { NGXTENSION_LOCAL_STORAGE, injectLocalStorage, provideLocalStorageImpl };
