import { DestroyRef, untracked, Injector, effect } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { assertInjector } from 'ngxtension/assert-injector';
import { Subscription, isObservable } from 'rxjs';
function connect(signal, ...args) {
  const [observable, reducer, injectorOrDestroyRef, useUntracked, originSignal] = parseArgs(args);
  if (observable) {
    let destroyRef = null;
    if (injectorOrDestroyRef instanceof DestroyRef) {
      destroyRef = injectorOrDestroyRef; // if it's a DestroyRef, use it
    } else {
      const injector = assertInjector(connect, injectorOrDestroyRef);
      destroyRef = injector.get(DestroyRef);
    }
    return observable.pipe(takeUntilDestroyed(destroyRef)).subscribe(x => {
      const update = () => {
        signal.update(prev => {
          if (!isObject(prev)) {
            return reducer?.(prev, x) || x;
          }
          if (!isObject(x)) {
            const reducedValue = reducer ? reducer(prev, x) : x;
            return isObject(reducedValue) ? {
              ...prev,
              ...reducedValue
            } : reducedValue;
          }
          return {
            ...prev,
            ...(reducer?.(prev, x) || x)
          };
        });
      };
      if (useUntracked) {
        untracked(update);
      } else {
        update();
      }
    });
  }
  if (originSignal) {
    const injector = injectorOrDestroyRef instanceof Injector ? assertInjector(connect, injectorOrDestroyRef) : undefined;
    return effect(() => {
      signal.update(prev => {
        if (!isObject(prev)) {
          return originSignal();
        }
        return {
          ...prev,
          ...originSignal()
        };
      });
    }, {
      allowSignalWrites: true,
      injector
    });
  }
  return {
    with(...args) {
      if (!this.subscription) {
        this.subscription = new Subscription();
      } else if (this.subscription.closed) {
        console.info(`[ngxtension connect] ConnectedSignal has been closed.`);
        return this;
      }
      this.subscription.add(connect(signal, ...args, injectorOrDestroyRef, useUntracked));
      return this;
    },
    subscription: null
  };
}
// TODO: there must be a way to parse the args more efficiently
function parseArgs(args) {
  if (args.length > 3) {
    return [args[0], args[1], args[2], args[3], null];
  }
  if (args.length === 3) {
    if (typeof args[2] === 'boolean') {
      if (isObservable(args[0])) {
        return [args[0], null, args[1], args[2], null];
      } else {
        return [null, null, args[1], args[2], args[0]];
      }
    }
    return [args[0], args[1], args[2], false, null];
  }
  if (args.length === 2) {
    if (typeof args[1] === 'boolean') {
      return [null, null, args[0], args[1], null];
    }
    if (typeof args[1] === 'function') {
      return [args[0], args[1], null, false, null];
    }
    return [args[0], null, args[1], false, null];
  }
  if (isObservable(args[0])) {
    return [args[0], null, null, false, null];
  }
  // to connect signals to other signals, we need to use a callback that includes a signal call
  if (typeof args[0] === 'function') {
    return [null, null, null, false, args[0]];
  }
  return [null, null, args[0], false, null];
}
function isObject(val) {
  return typeof val === 'object' && val !== undefined && val !== null && !Array.isArray(val);
}

/**
 * Generated bundle index. Do not edit.
 */

export { connect };
